import React, { useEffect, useState } from "react"
import Layout from "../../../../components/layout/layout"
import Content from "../../../../components/content/content"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get, fetch_patch } from "../../../../utils/fetch"

const RegistrationIncomplete = () => {
  const [ssoUrlLoading, setSsoUrlLoading] = useState({ loading: false, error: false })
  const [ssoUrl, setSsoUrl] = useState("")
  const { isLoading, error, isAuthenticated, user, logout } = useAuth0()
  const [theAuthUser, settheAuthUser] = useState(null)
  // const queryParams = new URLSearchParams(window.location.search)
  // var u = queryParams.get("u")

  // console.log(decryptWithAES(u, "heloo00001"))
  useEffect(() => {
    if (theAuthUser === null || theAuthUser === undefined) return
    // console.log(theAuthUser)
    setSsoUrlLoading({ loading: true, error: false })
    fetch_get(`/sso/url?email=${theAuthUser.email}`).then(res => {
      if (res.url) {
        setSsoUrl(res.url)
        setSsoUrlLoading({ loading: false, error: false })
      } else {
        setSsoUrl("")
        setSsoUrlLoading({ loading: false, error: true })
      }
    })
  }, [theAuthUser])

  useEffect(() => {
    if (theAuthUser !== null) return
    var theAuthUserobj = sessionStorage.theAuthUser || null
    if (theAuthUserobj !== null) theAuthUserobj = JSON.parse(theAuthUserobj)
    settheAuthUser(theAuthUserobj)
  }, [])

  return (
    <Layout pageTitle="User Registration Incomplete">
      <Content>
        {theAuthUser !== null && theAuthUser !== undefined && (
          <>
            {/* <div className="alert alert-danger" role="alert">
              Hello <strong>{theAuthUser.name || ""}</strong>
              <br />
              <strong>
                You are not yet completely set up for CodeLinaro. Please go to <br />
                <a href={ssoUrl} target="_blank">
                  {ssoUrl}
                </a>
              </strong>
            </div> */}
            {!ssoUrlLoading.loading && (
              <>
                {!ssoUrlLoading.error && (
                  <>
                    <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">You are almost there!</h4>
                      <p>
                        Please click the following link to complete your account registration. Once
                        the setup has been completed, please login again.
                      </p>
                      <hr />
                      <p className="mb-0">
                        <a href={ssoUrl} style={{ color: "gray" }} target="_blank">
                          {ssoUrl}
                        </a>
                      </p>
                    </div>
                  </>
                )}

                {ssoUrlLoading.error && (
                  <>
                    <div className="alert alert-danger" role="alert">
                      <h4 className="alert-heading">oops!</h4>
                      <p>
                        We are sorry ! Something went wrong during the process. Please contact the
                        admnistrator
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        {/* {(theAuthUser === null || theAuthUser === undefined) && (
          <>
            <div className="alert alert-danger" role="alert">
              Invalid request
            </div>
          </>
        )} */}
      </Content>
    </Layout>
  )
}

export default RegistrationIncomplete
